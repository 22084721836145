jQuery(($) => {
	var ppp = 6;
	var pageNumber = 2;
	var pp = 21;
	var category = "all";

	function loadPosts(categorySlug, button, append) {
		var btnText = button.text();
		button.text("Loading...");

		if (append == false) {
			pageNumber = 1;
		}

		category = categorySlug;

		var str =
			"&pageNumber=" +
			pageNumber +
			"&ppp=" +
			ppp +
			"&type=post&action=more_post_ajax" +
			"&category=" +
			category;

		$.ajax({
			type: "POST",
			dataType: "html",
			url: ajax_posts.ajaxurl,
			data: str,
			beforeSend: function (jqXHR) {
				button.text("Loading...");
			},
			success: function (data) {
				button.text(btnText);

				if (data) {
					if (append) {
						$(".recent-posts .row").append(data);
					} else {
						$(".recent-posts .row").html(data);
					}
					button.show();
				} else {
					button.hide();
					console.log("No More Items");
				}
			},
			afterSend: function (jqXHR) {
				button.text("More posts").prev().before(data);
			},
			error: function (jqXHR, textStatus, errorThrown) {
				$loader.html(
					jqXHR + " :: " + textStatus + " :: " + errorThrown
				);
			},
		});
		pageNumber++;
	}

	$(".category--select").on("change", function () {
		const selectedValue = $(this).val();
		loadPosts(selectedValue, $(".more-posts.read--more.btn"), false);
	});

	$(".category--buttons span").on("click", function () {
		var categorySlug = $(this).data("category");
		$(".category--buttons span").removeClass("active");
		$(this).addClass("active");
		$(".more-posts.read--more.btn").attr("data-category", categorySlug);
		category = categorySlug;
		loadPosts(categorySlug, $(".more-posts.read--more.btn"), false);
	});

	$(".more-press-container.blog .more-posts.read--more.btn").on(
		"click",
		function () {
			//var categorySlug = $(this).data("category");
			console.log(category);
			//category = categorySlug;
			loadPosts(category, $(this), true);
		}
	);

	/* $(".category--buttons span").on("click", function () {
		var categorySlug = $(this).data("category");
		$(".category--buttons span").removeClass("active");
		$(this).addClass("active");

		$(".more-posts.read--more.btn").attr(
			"data-category",
			categorySlug
		);

		var str =
			"&pageNumber=" +
			pageNumber +
			"&ppp=" +
			ppp +
			"&type=post&action=more_post_ajax" +
			"&category=" +
			categorySlug;

		$.ajax({
			type: "POST",
			dataType: "html",
			url: ajax_posts.ajaxurl,
			data: str,
			beforeSend: function (jqXHR) {
				button.text("Loading...");
			},
			success: function (data) {
				if (data) {
					$(".recent-posts .row").html(data);
					button.text(btnText);
				}
			},
			afterSend: function (jqXHR) {
				button.text("More posts").prev().before(data);
			},
			error: function (jqXHR, textStatus, errorThrown) {
				$loader.html(
					jqXHR + " :: " + textStatus + " :: " + errorThrown
				);
			},
		});
		pageNumber++;
	});

	$(".more-press-container.blog .more-posts.read--more").on(
		"click",
		function () {
			var categorySlug = $(this).data("category");

			var button = $(this);
			var btnText = button.text();
			button.text("Loading...");

			var str =
				"&pageNumber=" +
				pageNumber +
				"&ppp=" +
				ppp +
				"&type=post&action=more_post_ajax" +
				"&category=" +
				categorySlug;

			$.ajax({
				type: "POST",
				dataType: "html",
				url: ajax_posts.ajaxurl,
				data: str,
				beforeSend: function (jqXHR) {
					button.text("Loading...");
				},
				success: function (data) {
					if (data) {
						$(".recent-posts .row").append(data);
						button.text(btnText);
					} else {
						button.hide();
						console.log("No More Items");
					}
				},
				afterSend: function (jqXHR) {
					button.text("More posts").prev().before(data);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					$loader.html(
						jqXHR + " :: " + textStatus + " :: " + errorThrown
					);
				},
			});
			pageNumber++;
		}
	); */

	/* $(".more-press-container.newsroom .more-posts.read--more").on(
		"click",
		function () {
			console.log("clicked");

			var button = $(this);
			var btnText = button.text();
			button.text("Loading...");

			var str =
				"&pageNumber=" +
				pageNumber +
				"&ppp=" +
				ppp +
				"&type=post&action=more_post_ajax_newsroom";

			$.ajax({
				type: "POST",
				dataType: "html",
				url: ajax_posts.ajaxurl,
				data: str,
				beforeSend: function (jqXHR) {
					button.text("Loading...");
				},
				success: function (data) {
					if (data) {
						$(".row.press--content .press-grid").append(data);
						button.text(btnText);
					} else {
						button.hide();
						console.log("No More Items");
					}
				},
				afterSend: function (jqXHR) {
					button.text("More posts").prev().before(data);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					$loader.html(
						jqXHR + " :: " + textStatus + " :: " + errorThrown
					);
				},
			});
			pageNumber++;
		}
	); */

	/* $(".more-archive-container .more-posts.read--more").on(
		"click",
		function () {
			console.log("clicked");

			var button = $(this);
			var btnText = button.text();
			button.text("Loading...");

			var str =
				"&pageNumber=" +
				pageNumber +
				"&pp=" +
				pp +
				"&type=archive&action=more_archive_ajax";

			console.log(pp);
			console.log(pageNumber);

			$.ajax({
				type: "POST",
				dataType: "html",
				url: ajax_posts.ajaxurl,
				data: str,
				beforeSend: function (jqXHR) {
					button.text("Loading...");
				},
				success: function (data) {
					if (data) {
						$(".row.archive--content").append(data);
						button.text(btnText);
					} else {
						button.hide();
						console.log("No More Items");
					}
				},
				afterSend: function (jqXHR) {
					button.text("More posts").prev().before(data);
				},
				error: function (jqXHR, textStatus, errorThrown) {
					$loader.html(
						jqXHR + " :: " + textStatus + " :: " + errorThrown
					);
				},
			});
			pageNumber++;
		}
	); */
});
